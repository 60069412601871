<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />

    <section class="hero-media">
        <Carousel :items="[{image: mediaInfo.img}]" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
            <h2 class="entry-title">Schedule Tour</h2>
            <div class="entry-content">
                <p>Select a Date & Time</p>
                <ScheduleForm @submit="onSubmit" :users="users" :event="event" />
            </div>
		</div>
        <CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import ScheduleForm from '../../organisms/ScheduleForm/ScheduleForm.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import SingleBlog from '../../organisms/SingleBlog/SingleBlog.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, SocialBox, SingleBlog, ScheduleForm, Carousel, CopyRight
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        users: {
            type: Array,
            default: () => []
        },
        event: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            footerProps
        }
    },
    methods: {
        onSubmit(data) {
            this.$emit("submit", data);
        }
    },
}
</script>

<style>

</style>
<template>
  <div class="w-full">
    <div class="w-full flex gap-8">
      <div class="flex-1">
        <Calendar :min-date="new Date()" @dayclick="selectDate" :attributes='attributes' :timezone="timezone" />
      </div>
      <div class="flex flex-col gap-x-8 w-48 space-y-2 h-96 overflow-y-scroll px-2">
        <div @click="() => selectedTime = schedule" v-for="schedule in schedules" :key="schedule" :class="selectedTime === schedule && 'bg-indigo-600 hover:border-indigo-600'" class="cursor-pointer w-full flex items-center justify-center py-2 rounded-md border-2 border-indigo-600 hover:border-indigo-400">
          <Text size="" weight="semibold" :color="selectedTime === schedule ? 'white': 'indigo-600'" customClass="hover:text-indigo-400" :content="schedule"/>
        </div>
      </div>
    </div>
    <p class="my-5">Available tour times for <span>{{ selectedDate ? moment(selectedDate).add('hours', 12).format("dddd, MMMM DD, YYYY") : '' }}</span></p>
    <div class="flex gap-2 items-center">
      <label class="text-base font-medium text-gray-900 dark:text-gray-300">Timezone</label>
      <select v-model="timezone" class="basic-selector mb-0">
        <option v-for="tz in timezones" :key="tz.name" :value="tz.name">{{tz.label}}</option>
      </select>
    </div>
    <button @click="submit" class="button-filled button-color submit-btn mt-5" :class="{'cursor-not-allowed': !selectedDate}" size="size" variant="abc">Schedule Tour</button>
  </div>
</template>
  
<script>
import { Calendar, DatePicker } from 'v-calendar';
import { timezones } from '../../constants';
import 'v-calendar/dist/style.css';
import moment from 'moment';
import Text from '../../atoms/Text/Text.vue'
export default {
    components: {
        Calendar, DatePicker, Text
    },
    props: {
      defaultTimezone: {
        type: String,
        default: 'UTC'
      },
      users: {
        type: Array,
        default: () => []
      },
      event: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        timezone: this.defaultTimezone,
        timezones: timezones,
        moment, moment,
        selectedDate: null,
        attributes: [
          // This is a single attribute
          {
            dot: true,
            dates: new Date(),
            order: 0
          }
        ],
        schedules: [],
        selectedTime: null
      }
    },
    created() {
    },
    watch: {
      selectedDate: function (val) {
        const interval = parseInt(this.event.duration?.split('M')?.[1])
        this.schedules = [];
        const day = new Date(val).getDay();
        const schedule = this.users[0].schedule.find(s => s.weekday === day);
        schedule.clockIns?.map(c => {
          for (let i = 0; i < 100; i++) {
            const a = moment(c.clockIn).add('minutes', interval * i);
            const b = moment(c.clockIn).add('minutes', interval * (i + 1));
            if (moment(c.clockOut).diff(b, 'minutes', true) < 0) {
              break
            }
            this.schedules.push(a.format('hh:mm A'))
          }
        })
      }
    },
    methods: {
      submit() {
        if (this.selectedDate && this.selectedTime) {
          this.$emit("submit", {
            date: this.selectedDate,
            time: this.selectedTime,
            timezone: this.timezone
          });
        }
      },
      selectDate(date, e) {
        this.selectedDate = new Date(date.id);
        if (this.selectedDate > new Date()) {
          this.attributes = [
            {
              dot: true,
              dates: new Date(),
              order: 0
            },  
            {
              dates: new Date(date.id),
              highlight: true,
              order: 0
            }
          ];
        }
      }
    },
  };
</script>

<style>
.vc-container {
  min-width: 100%;
}

.vc-container .vc-time-content .vc-time-date {
  display: none;
}

.vc-container button {
  margin: 0 !important;
}

.vc-select select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  width: 52px !important;
  height: 30px !important;
  font-size: var(--text-base) !important;
  font-weight: var(--font-medium) !important;
  text-align: left !important;
  background-color: var(--gray-200) !important;
  border: 2px solid;
  border-color: var(--gray-200) !important;
  color: var(--gray-900) !important;
  padding: 0 20px 0 8px !important;
  border-radius: var(--rounded) !important;
  line-height: var(--leading-tight) !important;
  text-indent: 0px !important;
  cursor: pointer !important;
  -moz-padding-start: 3px !important;
  background-image: none !important;
  margin-bottom: 0 !important;
}

.basic-selector {
  border: 1px solid #e4e4e7 !important;
  border-radius: .5rem !important;
}

.vc-day-content {
  width: 48px;
  height: 48px;
  font-size: 22px;
}

.vc-highlight {
  width: 40px;
  height: 40px;
}
</style>
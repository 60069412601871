<template>
  <SchedulingTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :mediaInfo="mediaInfo"
    :users="users"
    :event="event"
    @submit="onSubmit"
  />
</template>

<script>
import SchedulingTemplate from "@/components/templates/Scheduling/Scheduling";
import { headerProps, socialProps } from "@/components/constants.js";
import heroImg from "@/assets/images/sample-hero.jpg";
import { Settings } from "../../settings";
import moment from "moment";
export default {
  components: {
    SchedulingTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      mediaInfo: {
        img: heroImg,
      },
      users: [],
      event: null,
    };
  },
  async mounted() {
    const events = await this.actions.event.getEvents({where: {type: 'CALENDAR', subtype: 'ONE_ON_ONE'}, getValues: true});
    // if (events.length === 0) {
    //   this.$router.go(-1);
    //   return false;
    // }
    console.log(events, 'events')
    this.event = events[0]
    Settings.tourUsers.map(async u => {
      const users = await this.actions.user.getUsers({where: {id: u}, getValues: true});
      console.log(users, 'users')
      this.users.push(users[0])
    })
  },
  methods: {
    async onSubmit(data) {
      console.log(data, 'data')
      const time = data.time?.split(' ')?.join('');
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time?.slice(-2);
      if(AMPM == "PM" && hours<12) hours = hours+12;
      if(AMPM == "AM" && hours==12) hours = hours-12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if(hours<10) sHours = "0" + sHours;
      if(minutes<10) sMinutes = "0" + sMinutes;
      const startTime = new Date(moment(data.date).add('hours', 10).format('YYYY-MM-DD') + 'T' + sHours + ":" + sMinutes + ':00');
      const endTime = new Date(startTime.getTime() + parseInt(this.event.duration?.split('M')?.[1]) * 60 * 1000) ;
      const {saveCalendar} = await this.actions.calendar.saveCalendar({
        data: {
          owner: {connect: {id: this.event.creator?.id}},
          attendees: {
            connect: [{id: this.$route.query?.uId}]
          },
          startTime,
          endTime,
          url: `https://${Settings.mainDomain}/#/calendar/${this.$route.query?.uId}/${this.event?.id}`,
          // description: form.message
        }
      })
      console.log(saveCalendar, 'saveCalendar')
      this.$router.push({name: 'Booking Success', isTour: true})
      // this.$router.push({name: 'Payment', query: {...this.$route.query, cId: saveCalendar?.id}})
      // this.$router.push({name: 'Schedule User', query: {eventId: this.$route.query.eventId, date: moment(data.date).add('hours', 10).format('YYYY-MM-DD'), time: data.time?.split(' ')?.join(''), tz: data.timezone} })
    }
  }
};
</script>

<style scoped></style>
